<template>
    <div id="AgentCooperation">
        <van-nav-bar
        :fixed="true"
        placeholder
        :title="$t('My.AgentCooperation.AgentCooperation')"
        left-arrow
        @click-left="onClickLeft"
        />
        <div class="content">
            <div class="Text">
                <p>{{$t('My.AgentCooperation.One')}}</p>
                <br>
                <p>{{$t('My.AgentCooperation.Two')}}</p>
                <br>
                <p>{{$t('My.AgentCooperation.Three')}}</p>
                <br>
                <p>{{$t('My.AgentCooperation.Four')}}</p>
                <p>{{$t('My.AgentCooperation.Five')}}</p>
                <br>
                <p class="p-text">{{$t('My.AgentCooperation.Six')}}</p>
                <br>
                <p class="p-text">{{$t('My.AgentCooperation.Seven')}}</p>
                <br>
                <p class="p-text">{{$t('My.AgentCooperation.Eight')}}</p>
                <br>
                <p class="p-text">{{$t('My.AgentCooperation.Nine')}}</p>
                <br>
                <p>{{$t('My.AgentCooperation.Ten')}}</p>
                <p>{{$t('My.AgentCooperation.Eleven')}}</p>
                <br>
                <p class="p-text">{{$t('My.AgentCooperation.Twelve')}}</p>
                <br>
                <p class="p-text" style="color:red;">{{$t('My.AgentCooperation.Thirteen')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Row,
        Col,
        Icon,
        Cell,
        GridItem,
        Grid,
        NavBar,
        ActionSheet,
        Button,
        Image 
    }from "vant";

    export default {
        name:"AgentCooperation",
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Icon.name]: Icon,
            [Cell.name]: Cell,
            [GridItem.name]: GridItem,
            [Grid.name]: Grid,
            [NavBar.name]: NavBar,
            [ActionSheet.name]: ActionSheet,
            [Button.name]: Button,
            [Image.name]:Image
        },
        data(){
            return{
                
            }
        },
        methods:{
            // 返回上一个路由
            onClickLeft(){
                this.$router.go(-1);
            },
        
        },
        created() {
            window.scroll(0, 0);
        },
    }
</script>

<style lang="less">
    #AgentCooperation{
        width: 100%;
        height: 100%;
        .content{
            width: calc(100% - 1.25rem);
            padding: 0.9375rem 0.625rem;
            background-color: #f2f2f2;
            >.Text{
                width: calc(100% - 1.25rem);
                padding: 1.25rem 0.625rem;
                border-radius: 0.625rem;
                background-color: #fff;
                font-size: 14px;
                color: #333;
                .p-text{
                    font-weight: 700;
                }
            }
        }
    }
</style>